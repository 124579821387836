.banner {
    @apply bg-[#001726] text-[#F0F6FF] md:pt-32 pt-12 min-h-screen;
}

.banner h1 {
    @apply md:mb-10 mb-5;
}

.banner p {
    @apply text-[#F0F6FF] opacity-80 font-normal;
}

/* === about === */

.about {
    @apply md:mt-40 mt-20;
}

.about h2 {
    @apply md:mb-10 mb-5;
}

.about p {
    @apply md:mb-10 mb-5;
}

/* === services === */

.services {
    @apply md:mt-40 mt-20;
}

.services-card {
    @apply grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 md:mt-20 mt-12;
}

.services-card div {
    @apply rounded-xl bg-[#F0F6FF] hover:-translate-y-3 text-white p-5;
}

.card-heading {
    @apply text-xl font-bold mb-5 text-liteBlack;
}

/* === interface === */

.interface {
    @apply md:mt-40 mt-20;
}

.interface h2 {
    @apply md:mb-10 mb-5;
}


.interface ul {
    @apply my-5;
}

.interface ul li {
    @apply flex justify-start items-center gap-2 my-2 text-lg font-medium text-[#3a3a3a];
}

.interface ul li svg {
    @apply w-6 h-6 text-bgsecondary;
}

/* === steps === */

.steps {
    @apply md:mt-40 mt-20;
}

.steps-card {
    @apply grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 md:mt-20 mt-12;
}

.steps-card div {
    @apply rounded-xl bg-[#F0F6FF] hover:-translate-y-3 text-white p-5;
}

.card-heading {
    @apply text-xl font-bold mb-5 text-liteBlack;
}

/* === slide-animation === */

.animtion-test {
    overflow: hidden;
}

.slide-animation {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }

}

/* === Testimonial === */

.Testimonial {
    @apply md:mt-40 mt-20;
}

/* .Testimonial h2 {
    @apply md:mb-10 mb-5;
} */

/* === Testimonial === */

.Price-plan li {
    @apply font-normal text-lg mt-2 flex justify-start items-center gap-3;
}

.Price-plan li .lucide-check {
    color: green;
}

.Price-plan li .lucide-x {
    color: red;
}

.Price-plan .price {
    @apply font-bold text-4xl my-5;
}

.Price-plan .price span {
    @apply font-light text-xl;
}

.Price-btn {
    @apply mt-5;
}

.Price-plan {
    @apply bg-[#F0F6FF] px-5 py-8 rounded-xl border-2 border-bgprimary;
}

.PricePlans {
    @apply md:mt-40 mt-20;
}

.PricePlans h2 {
    @apply md:mb-10 mb-5;
}

/* === Contact Form === */

.contact-form {
    @apply md:mt-40 mt-20;
}

.contact-form h2 {
    @apply md:mb-10 mb-5;
}

.contact-form p {
    @apply md:mb-10 mb-5;
}

.Testimonial .slick-slide {
    @apply px-3;
}

.Testimonial .slick-track {
    display: flex !important;
    align-items: center;
}

.Testimonial ul.slick-dots{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.Testimonial ul.slick-dots li{
    width: 10px;
    height: 10px;
    opacity: 1;
    background-color: red !important;
}
.Testimonial ul.slick-dots li button,
.Testimonial ul.slick-dots li button:before{
    content: none;
    display: none;
}

.banner-slider{
    @apply flex justify-center items-center;
}

.main-slider{
    @apply relative z-10;
}

.banner-slider-main {
    @apply w-[253px] relative;
}

.slider-img{
    position: absolute;
    top: 17px;
    right: 50%;
    transform: translateX(50%);
    z-index: 0;
    width: 218px;
    border: 1px solid red;
}