header {
    @apply sticky top-0 z-50 shadow-xl bg-white backdrop-blur-3xl text-liteBlack py-5;
}

header nav {
    @apply flex justify-between items-center;
}

header nav .button-logo {
    @apply flex items-center justify-between md:w-auto w-full;
}

.logo {
    @apply  max-w-[200px] block;
}

.header-menu {
    @apply md:flex space-y-4 md:space-y-0 md:space-x-6 mt-4 md:mt-0;
}

.header-menu li a {
    @apply text-lg font-medium text-[#3a3a3a] hover:text-bgsecondary;
}

.header-menu li a.active-menu {
    @apply text-bgsecondary;
}

.dropdown {
    @apply flex justify-start items-center md:flex-nowrap flex-wrap relative;
}

.dropdown .drop-arrow {
    @apply ml-1 cursor-pointer w-5;
}

.dropdown-arrow.add {
    @apply rotate-180 transition;
}

.dropdown-arrow.remove {
    @apply rotate-0 transition;
}

.dropdown-menus ul .nav-link a {
    @apply px-4 py-2 block transition;
}

.dropdown-menus ul .nav-link a:hover {
    @apply text-bgsecondary translate-x-1 transition;
}

.dropdown-menus {
    @apply md:absolute relative md:w-52 w-full md:right-0 right-auto md:top-10 top-auto md:mt-0 mt-2 bg-black text-white rounded shadow-lg transition;
}

.menu-links {
    @apply md:flex items-center transition;
}

.menu-open {
    @apply translate-x-0 md:relative absolute md:top-auto top-[66px] md:bg-transparent bg-gray md:shadow-none shadow-lg md:w-auto w-full md:p-0 p-3 md:px-0 px-10 md:left-0 left-0;
}

.menu-hidden {
    @apply md:translate-x-0 translate-x-[-100%] md:relative absolute md:top-auto top-[66px] md:bg-transparent bg-liteBlack md:shadow-none shadow-lg md:w-auto w-full md:p-0 p-3 md:px-0 px-10 md:left-0 left-0;
}



#nav-icon {
    width: 30px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #002d53;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}



#nav-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon.toggle-open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 0px;
}

#nav-icon.toggle-open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon.toggle-open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 20px;
    left: 0px;
}