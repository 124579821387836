@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
    transition: all 0.4s;
    font-family: "Rubik", sans-serif;
}

.container {
    @apply mx-auto;
}

.transition-all {
    transition: all 0.4s;
}

.transition-all-input {
    transition: all 1s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-bold m-0;
}

h1 {
    @apply md:text-5xl text-3xl;
}

h2 {
    @apply md:text-5xl sm:text-3xl text-2xl;
}

h3 {
    @apply text-3xl;
}

h4 {
    @apply text-2xl;
}

h5 {
    @apply text-xl;
}

h6 {
    @apply text-lg;
}

p {
    @apply md:text-lg text-sm font-normal text-[#3a3a3a];
}

.light-btn,
.dark-btn {
    @apply text-[#F0F6FF] md:px-10 px-8 md:py-4 py-3 flex justify-center items-center w-fit md:text-lg text-base font-medium rounded-full;
}

.dark-btn {
    @apply hover:bg-bgsecondary bg-bgprimary;
}

.light-btn {
    @apply bg-bgsecondary hover:bg-bgprimary;
}

textarea::placeholder,
input::placeholder{
    @apply transition-all-input translate-x-0;
}

textarea:focus::placeholder,
input:focus::placeholder{
    @apply transition-all-input -translate-x-full;
}


/* === Footer === */

footer {
    @apply bg-bgprimary text-white md:mt-40 mt-20 md:pt-20 pt-12;
}

footer p {
    @apply text-white mt-5 font-normal;
}

footer ul {
    @apply mt-5;
}

footer ul li {
    @apply mb-2;
}

footer ul li a {
    @apply text-[#F0F6FF] w-fit flex justify-start items-center gap-1 font-normal;
}

footer input {
    @apply bg-transparent border-2 border-white w-full rounded-full py-3 px-5 pr-14 text-white font-normal text-base focus:outline-0 focus:border-bgsecondary;
}

footer hr {
    @apply my-10;
}

footer .copy-right {
    @apply md:flex-nowrap flex-wrap flex justify-between items-center lg:gap-5 md:gap-10 gap-5 pb-10;
}

footer .copy-links {
    @apply flex justify-between items-center gap-5;
}

.newsletter {
    @apply relative mt-5;
}

.newsletter svg {
    @apply absolute right-5 top-1/2 -translate-y-1/2 w-6 h-6 cursor-pointer;
}

.error-message{
    @apply text-red mt-1 block;
}

/* === Footer === */
/* === Footer === */
.clamp-2-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
.policy {
    @apply md:my-28 my-20;
}

.policy h1 {
    @apply md:mb-10 mb-5;
}

.policy ul {
    @apply  pl-4;
}

.policy ul li{
    @apply list-disc;
}

.policy ul li span{
    @apply block;
}

.policy ul li,
.policy p {
    @apply md:mb-2 mt-1 mb-1;
}

.policy h6,
.policy h5,
.policy h3,
.policy h2,
.policy h4 {
    @apply md:mt-8 mt-4;
}

.policy a {
    @apply text-bgsecondary;
}
/* === Footer === */